export enum SegmentEventTypes {
  AcceptedCampaignInvite = 'accepted-campaign-invite',
  AcceptedPartnerships = 'accepted-partnerships',
  AcceptedPartnershipTerms = 'accepted-partnership-terms',
  ActivatedLinkinbio = 'activated-linkinbio',
  AddedCommentToPost = 'added-comment-to-post',
  AddedCreatorNote = 'added_creator_note',
  AddedCreatorToCampaign = 'added-creator-to-campaign',
  AddedInstantBackgroundImageToLibrary = 'added-instant-background-image-to-library',
  AddedLinkinbioToBio = 'added-linkinbio-to-bio',
  AddedLinkinbioToPost = 'added-linkinbio-to-post',
  AddedMediaKit = 'added-media-kit',
  AddedTeamMember = 'added-team-member',
  AddedToLibraryFromChromeExtension = 'added-to-library-from-chrome_extension',
  AddedToLibraryFromHashtag = 'added-to-library-from-hashtag',
  AddedToLibraryFromMentions = 'added-to-library-from-mentions',
  AddedToLibraryFromProfile = 'added-to-library-from-profile',
  AddedToLibraryFromSpark = 'added-to-library-from-spark',
  AddedToLibraryFromStockPhotos = 'added-to-library-from-stock-photos',
  AddedToLibraryFromTags = 'added-to-library-from-tags',
  AddedToLibraryFromUrl = 'added-to-library-from-url',
  AddedUserToGroup = 'added-user-to-group',
  AddedVideoCover = 'added-video-cover',
  AddressClickedSave = 'address-clicked-save',
  AddressSavedSuccessfully = 'address-saved-successfully',
  AddressViewedLoadingSpinner = 'address-viewed-loading-spinner',
  AddressViewedServerError = 'address-viewed-server-error',
  AddressViewedValidationError = 'address-viewed-validation-error',
  AiGenerationTimeout = 'ai-generation-timeout',
  AppliedPhotoFilter = 'applied-photo-filter',
  AppliedPhotoText = 'applied-photo-text',
  AppliedToCampaign = 'applied-to-campaign',
  ApprovedPublicSubmission = 'approved-public-submission',
  ArchivedCampaign = 'archived-campaign',
  AttemptedConnectingFacebook = 'attempted-connecting-facebook',
  AttemptedConnectingFacebookAccount = 'attempted-connecting-facebook-account',
  AttemptedConnectingInstagramAccount = 'attempted-connecting-instagram-account',
  AttemptedConnectingLinkedinAccount = 'attempted-connecting-linkedin-account',
  AttemptedConnectingPinterestAccount = 'attempted-connecting-pinterest-account',
  AttemptedConnectingTiktokAccount = 'attempted-connecting-tiktok-account',
  AttemptedConnectingTwitterAccount = 'attempted-connecting-twitter-account',
  AttemptedMultiPhotoPost = 'attempted-multi-photo-post',
  AttemptedUploadingMedia = 'attempted-uploading-media',
  AuthenticatedMavely = 'authenticated-mavely',
  BttpEnabled = 'bttp-enabled',
  BttpOptIn = 'bttp-opt-in',
  CancelledPaidAccountIntent = 'cancelled-paid-account-intent',
  CancelledSelectingPosts = 'cancelled-selecting-posts',
  ChangedCalendarTimezone = 'changed-calendar-timezone',
  ChangedCreatorStatus = 'changed-creator-status',
  ChangedLanguage = 'changed-language',
  ChangedOrder = 'Changed Order',
  ChangedPerformanceReportBenchmarkState = 'changed-performance-report-benchmark-state',
  ChangedPerformanceReportState = 'changed-performance-report-state',
  ChangedSocialProfilesForPost = 'changed-social-profiles-for-post',
  ChatbotCampaignActivated = 'chatbot-campaign-activated',
  ChatbotOpened = 'chatbot-opened',
  CheckedHashtagTrends = 'checked_hashtag_trends',
  ClearedPhotoText = 'cleared-photo-text',
  ClickAddInstagram = 'click-add-instagram',
  ClickedAddLinkinbioToSocialProfile = 'clicked-add-linkinbio-to-social-profile',
  ClickedAddToProfile = 'clicked-add-to-profile',
  ClickedBoostPostCta = 'clicked-boost-post-cta',
  ClickedBusinessProfileSetup = 'clicked-business-profile-setup',
  ClickedCalendarSettings = 'clicked-calendar-settings',
  ClickedCancellationAlternative = 'clicked-cancellation-alternative',
  ClickedChangePlan = 'clicked-change-plan',
  ClickedCheckProfileType = 'clicked-check-profile-type',
  ClickedClearBackground = 'clicked-clear-background',
  ClickedContent = 'clicked-content',
  ClickedContinuePartnershipsLanding = 'clicked-continue-partnerships-landing',
  ClickedConvertAffiliateLink = 'clicked-convert-affiliate-link',
  ClickedCopyLink = 'clicked-copy-link',
  ClickedCreateCampaign = 'clicked-create-campaign',
  ClickedCreateLink = 'clicked-create-link',
  ClickedCreatorPlanAccordion = 'clicked-creator-plan-accordion',
  ClickedEditProfile = 'clicked-edit-profile',
  ClickedEducationLink = 'clicked-education-link',
  ClickedGenerateAiCaptionsButton = 'clicked-generate-ai-captions-button',
  ClickedLaterApp = 'clicked-later-app',
  ClickedLearnMorePartnershipsLanding = 'clicked-learn-more-partnerships-landing',
  ClickedLearnMorePartnershipsTerms = 'clicked-learn-more-partnerships-terms',
  ClickedLegacyMigrationBanner = 'clicked-legacy-migration-banner',
  ClickedLinkinbioAlert = 'clicked-linkinbio-alert',
  ClickedLinkinbioBanner = 'clicked-linkinbio-banner',
  ClickedLinkinbioBlock = 'clicked-linkinbio-block',
  ClickedLinkinbioCsvExport = 'clicked-linkinbio-csv-export',
  ClickedLinkinbioFromPost = 'clicked-linkinbio-from-post',
  ClickedLinkinbioMavely = 'clicked-linkinbio-mavely',
  ClickedLinkinbioModal = 'clicked-linkinbio-modal',
  ClickedLinkinbioOce = 'clicked-linkinbio-oce',
  ClickedLinkinbioRecycler = 'clicked-linkinbio-recycler',
  ClickedLinkinbioUserIntent = 'clicked-linkinbio-intent',
  ClickedNameTooltip = 'clicked-name-tooltip',
  ClickedNav = 'clicked-nav',
  ClickedPlansSwitchModal = 'clicked-plans-switch-modal',
  ClickedPostApprovalList = 'clicked-post-approval-list',
  ClickedReactivatePaidAccount = 'clicked-reactivate-paid-account',
  ClickedReactivationTraining = 'clicked-reactivation-training',
  ClickedRefineContentIdea = 'clicked-refine-content-idea',
  ClickedShareCalendarIntent = 'clicked-share-calendar-intent',
  ClickedShareMediaKit = 'clicked-share-media-kit',
  ClickedSocialSetSwitcher = 'clicked-social-set-switcher',
  ClickedToAnalyticsDetailFromPost = 'clicked-to-analytics-detail-from-post',
  ClickedTryTestImage = 'clicked-try-test-image',
  ClickedUpdateBackground = 'clicked-update-background',
  ClickedUpgradeButton = 'clicked-upgrade-button',
  ClickedUpgradeButtonCalendarSettings = 'clicked-upgrade-button-calendar-settings',
  ClickedUpgradeIntent = 'clicked-upgrade-intent',
  ClickedViewCampaign = 'clicked-view-campaign',
  ClickedViewComments = 'clicked-view-comments',
  ClickedVisitThirdParty = 'clicked-visit-third-party',
  ClickOptIn = 'click-opt-in',
  ClosedPostBuilder = 'closed-post-builder',
  CollectedUserGoalsSignup = 'collected-user-goals-signup',
  CompletedAutoScheduleWizard = 'completed-auto-schedule-wizard',
  CompletedMultiImageWebTutorial = 'completed-multi-image-web-tutorial',
  ConfirmedCampaignParticipation = 'confirmed-campaign-participation',
  ConnectedInstagramCatalogue = 'connected-instagram-catalogue',
  ConnectedMobileOnboarding = 'connected-mobile-onboarding',
  CopiedContentIdea = 'copied-content-idea',
  CopiedLinkinbioUrl = 'copied-linkinbio-url',
  CopiedPost = 'copied-post',
  CreatedCampaign = 'created-campaign',
  CreatedDraft = 'created-draft',
  CreatedGroup = 'created-group',
  CreatedLabel = 'created-label',
  CreatedNote = 'created-note',
  CustomizationIntent = 'customization-intent',
  CustomizedLinkinbioGallery = 'customized-linkinbio-gallery',
  DeclinedBttp = 'declined-bttp',
  DeclinedTrialOffer = 'declined-trial-offer',
  DeletedAccount = 'deleted-account',
  DeletedContentIdea = 'deleted-content-idea',
  DeletedDraft = 'deleted-draft',
  DetectedEligibleAffiliateLink = 'detected-eligible-affiliate-link',
  DisabledLinkinbio = 'disabled-linkinbio',
  DiscardedMultiProfilePosts = 'discarded-multi-profile-posts',
  DismissedLinkinbioDiscoveryPreview = 'dismissed-linkinbio-discovery-preview',
  DismissedSocialConnectModal = 'dismissed-social-connect-modal',
  EditedCampaign = 'edited-campaign',
  EditedCreatorProfile = 'edited-creator-profile',
  EditedDraft = 'edited-draft',
  EditedScheduledPost = 'edited-scheduled-post',
  EnabledIgAnalyticsEmail = 'enabled-ig-analytics-email',
  EnabledLinkinbio = 'enabled-linkinbio',
  EnteredLinkinbioGallerySetup = 'entered-linkinbio-gallery-setup',
  ExportedCsv = 'exported-csv',
  FilteredConversations = 'filtered-conversations',
  FilteredMediaLibrary = 'filtered-media-library',
  FilteredResults = 'filtered-results',
  FilteredSideLibrary = 'filtered-side-library',
  GeneratedAiCaptions = 'generated-ai-captions',
  GeneratedContentIdeas = 'generated-content-ideas',
  GeneratedContentPillars = 'generated-content-pillars',
  ImportedMedia = 'imported-media',
  InitiatedEditCreatorProfile = 'initiated-edit-creator-profile',
  InitiatedInfluencerOptIn = 'initiated-influencer-opt-in',
  InsertedAiCaption = 'inserted-ai-caption',
  InvitedContributor = 'invited-contributor',
  InvitedCreatorToCampaign = 'invited-creator-to-campaign',
  JoinedCreatorDatabase = 'joined-creator-database',
  LabelledMedia = 'labelled-media',
  LoadedPost = 'loaded-post',
  ManagedMediaKitInstagram = 'managed-media-kit-instagram',
  ManagedMediaKitTiktok = 'managed-media-kit-tiktok',
  ManuallyDiscardedUnverifiedPost = 'manually-discarded-unverified-post',
  ManuallyMatchedPost = 'manually-matched-post',
  ManuallyRescheduledUnverifiedPost = 'manually-rescheduled-unverified-post',
  MavelySuccessfullyConnected = 'mavely-successfully-connected',
  ModeratedConversation = 'moderated-conversation',
  MovedSocialProfile = 'moved-social-profile',
  NavigatedToNextPost = 'navigated-to-next-post',
  NavigatedToPreviousPost = 'navigated-to-previous-post',
  OpenedActivityPanel = 'opened-activity-panel',
  OpenedAiCaptionWriter = 'opened-ai-caption-writer',
  OpenedApplication = 'opened-application',
  OpenedFeatureLink = 'opened-feature-link',
  OpenedFeatureModal = 'opened-feature-modal',
  OpenedHashtagSuggestions = 'opened-hashtag-suggestions',
  OpenedInAppNotification = 'opened-in-app-notification',
  OpenedNotificationPanel = 'opened-notification-panel',
  OpenedPhotoTextEditor = 'opened-photo-text-editor',
  OpenedSurvey = 'opened-survey',
  OpenedTraining = 'opened-training',
  OptedInForTrialCalendarSettings = 'opted-in-for-trial-calendar-settings',
  RefreshedBttp = 'refreshed-bttp',
  RefreshFacebookTokenFailure = 'refresh_facebook_token_failure',
  RejectedCampaignInvite = 'rejected-campaign-invite',
  RemovedCreatorFromCampaign = 'removed-creator-from-campaign',
  RemovedUserFromGroup = 'removed-user-from-group',
  RepliedToConversation = 'replied-to-conversation',
  SavedNewCaption = 'saved-new-caption',
  ScheduledAtBestTime = 'scheduled-at-best-time',
  ScheduledMultiProfile = 'scheduled-multi-profile',
  ScheduledStory = 'scheduled-story',
  ScheduledVideoIncorrectTrimmedVideo = 'scheduled-video-incorrect-trimmed-video',
  SearchedContent = 'searched-content',
  SearchedCreators = 'searched-creators',
  SearchedInstagram = 'searched-instagram',
  SearchedInstagramMax = 'searched-instagram-max',
  SearchedProfile = 'searched-profile',
  SearchedStock = 'searched-stock',
  SelectedAnalyticsTimePeriod = 'selected-analytics-time-period',
  SelectedPlanForTrial = 'selected-plan-for-trial',
  SelfReportedContent = 'self-reported-content',
  SetUserName = 'set-user-name',
  SharedInstagramPlan = 'shared-instagram-plan',
  SharedLinkToEmail = 'shared-link-to-email',
  ShowedApplicationIntent = 'showed-application-intent',
  SignedInAccount = 'signed-in-account',
  SignedUpViaCampaign = 'signed-up-via-campaign',
  SignupAttemptAlreadyExists = 'signup-attempt-already-exists',
  SkippedIndustryComparisonSelfCat = 'skipped-industry-comparison-self-cat',
  SortedByTableCol = 'sorted-by-table-col',
  StarredMedia = 'starred-media',
  StartedAutoScheduleWizard = 'started-auto-schedule-wizard',
  StartedCopyPost = 'started-copy-post',
  StartedSchedulingPost = 'started-scheduling-post',
  StartedSelectingPosts = 'started-selecting-posts',
  StayedWithLater = 'stayed-with-later',
  SubmittedSurvey = 'submitted-survey',
  SuggestedHashtags = 'suggested-hashtags',
  SwitchedIndustryFilter = 'switched-industry-filter',
  TaggedShoppablePost = 'tagged-shoppable-post',
  TestedMobileNotificationOnboarding = 'tested-mobile-notification-onboarding',
  ToggledBestTimeToPost = 'toggled-best-time-to-post',
  ToggledEnabledMediaKit = 'toggled-enabled-media-kit',
  ToggledInfluencerOptIn = 'toggled-influencer-opt-in',
  ToggledShowCalendarNotes = 'toggled-show-calendar-notes',
  TogglePublicCampaign = 'toggle-public-campaign',
  TransitionedToOce = 'transitioned-to-oce',
  UpdatedContentPillar = 'updated-content-pillar',
  UpdatedEmailNotification = 'updated-email-notification',
  UpdatedLinkinbioBlock = 'updated-linkinbio-block',
  UpdatedLinkinbioGalleryCodeSettings = 'updated-linkinbio-gallery-code-settings',
  UpdatedLinkinbioSetting = 'updated-linkinbio-setting',
  UpdatedMediaAltText = 'updated-media-alt-text',
  UpdatedMediaKit = 'updated-media-kit',
  UpdatedMediaKitDesignBlock = 'updated-media-kit-design-block',
  UpdatedMediaKitImageBlock = 'updated-media-kit-image-block',
  UpdatedMediaKitProfileBlock = 'updated-media-kit-profile-block',
  UpdatedMediaKitRateBlock = 'updated-media-kit-rate-block',
  UpdatedMediaNotes = 'updated-media-notes',
  UpdatedPerformanceReportManually = 'updated-performance-report-manually',
  UpdatedTrialPlan = 'updated-trial-plan',
  UsedSavedCaption = 'used-saved-caption',
  UserOpenedChabot = 'user-opened-chabot',
  ViewedAdvancedAnalytics = 'viewed-advanced-analytics',
  ViewedCreatorApplicants = 'viewed-creator-applicants',
  ViewedCreatorProfileEditor = 'viewed-creator-profile-editor',
  ViewedDraft = 'viewed-draft',
  ViewedErrorMessage = 'viewed-error-message',
  ViewedFeatureCard = 'viewed-feature-card',
  ViewedHomepage = 'viewed-homepage',
  ViewedIgAnalyticsPostDetail = 'viewed-ig-analytics-post-detail',
  ViewedIndustryComparisonSelfCat = 'viewed-industry-comparison-self-cat',
  ViewedNote = 'viewed-note',
  ViewedOpenCalendar = 'viewed-open-calendar',
  ViewedPage = 'viewed-page',
  ViewedPost = 'viewed-post',
  ViewedPreviewGrid = 'viewed-preview-grid',
  ViewedProfileDetailsMultiCal = 'viewed-profile-details-multi-cal',
  ViewedSchedulingOnboardingModal = 'viewed-scheduling-onboarding-modal',
  ViewedStarredMedia = 'viewed-starred-media',
  ViewedStoryTab = 'viewed-story-tab',
  ViewedTableView = 'viewed-table-view',
  ViewedTiktokAnalyticsPostDetail = 'viewed-tiktok_analytics-post-detail',
  WarningModalDisplayed = 'warning-modal-displayed',
  WatchedVideo = 'watched-video'
}
